import React, { useEffect, useRef, useState } from "react";
import "./App.css";

function App() {
    // Constants
    // Constants
    const canvasRef = useRef(null);
    const grid_size = 15;
    let canvasWidth = window.innerWidth + grid_size * 2;
    let canvasHeight = window.innerHeight + grid_size * 2;
    let numCols = Math.floor(canvasWidth / grid_size);
    let numRows = Math.floor(canvasHeight / grid_size);
    let mousePosition = { x: 0, y: 0 };
    const frameTime = 1000 / 24;
    let lastFrameTime = 0;

    // State
    const [firesOn, setFiresOn] = React.useState(true);

    // Grid Configuration
    const gridConfig = {
        fireSpawnChance: 0.001,
        fireSpreadChance: 0.6,
        treeStartChance: 0.05,
        treeGrowChance: 0.04,
        fireColor: "#F97B22",
        treeColor: "#406037",
        emptyColor: "#BDD2B6",
        treeColorHighlighted: "#609966",
    };

    // Initialize the grid
    let grid = [];
    for (let i = 0; i < numCols; i++) {
        grid[i] = [];
        for (let j = 0; j < numRows; j++) {
            if (Math.random() < gridConfig.treeStartChance) {
                grid[i][j] = 1;
            } else {
                grid[i][j] = 0;
            }
        }
    }

    // Randomly choose a cell to start the fire
    const x = Math.floor(Math.random() * numCols);
    const y = Math.floor(Math.random() * numRows);
    grid[x][y] = 2;

    function handleResize() {
        window.location.reload();
    }
    window.addEventListener("resize", handleResize);

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        // Canvas setup
        let canvas = canvasRef.current;
        let ctx = canvas.getContext("2d");
        let offscreenCanvas = document.createElement("canvas");
        offscreenCanvas.width = canvasWidth;
        offscreenCanvas.height = canvasHeight;
        let offscreenCtx = offscreenCanvas.getContext("2d");

        // Handle mouse movement
        function handleMouseMove(event) {
            const x = Math.floor(event.clientX / grid_size);
            const y = Math.floor(event.clientY / grid_size);
            mousePosition = { x, y };
        }
        window.addEventListener("mousemove", handleMouseMove);

        // Draw the grid
        function drawGrid() {
            // Clear the offscreen canvas instead of the visible canvas
            offscreenCtx.clearRect(0, 0, canvasWidth, canvasHeight);

            // Draw cells that have changed
            for (let i = 0; i < numCols; i++) {
                for (let j = 0; j < numRows; j++) {
                    const x = i * grid_size;
                    const y = j * grid_size;
                    const value = grid[i][j];
                    if (value === 1) {
                        offscreenCtx.fillStyle = gridConfig.treeColor;
                    } else if (value === 2) {
                        offscreenCtx.fillStyle = gridConfig.fireColor;
                    } else if (value === 3) {
                        offscreenCtx.fillStyle = gridConfig.treeColorHighlighted;
                    } else {
                        offscreenCtx.fillStyle = gridConfig.emptyColor;
                    }
                    offscreenCtx.fillRect(x, y, grid_size, grid_size);
                }
            }

            // Copy the offscreen canvas to the visible canvas
            ctx.clearRect(0, 0, canvasWidth, canvasHeight);
            ctx.drawImage(offscreenCanvas, 0, 0);
        }

        // Update the grid
        function updateGrid(numCols, numRows) {
            if (!firesOn) {
                // Remove fire, let trees grow, and return
                for (let i = 0; i < numCols; i++) {
                    for (let j = 0; j < numRows; j++) {
                        if (grid[i][j] == 2) {
                            grid[i][j] = 0;
                        } else if (grid[i][j] == 0 && Math.random() < gridConfig.treeGrowChance) {
                            grid[i][j] = 1;
                        }
                    }
                }
                return;
            }

            // Create a new grid to store updated values
            let newGrid = [];
            for (let i = 0; i < numCols; i++) {
                newGrid[i] = [];
                for (let j = 0; j < numRows; j++) {
                    newGrid[i][j] = grid[i][j];
                }
            }

            // Update the new grid
            for (let i = 0; i < numCols; i += 1) {
                for (let j = 0; j < numRows; j += 1) {
                    const distanceToMouse = Math.sqrt(
                        Math.pow(mousePosition.x - i, 2) + Math.pow(mousePosition.y - j, 2)
                    );

                    // Looking around the current cell
                    let sum = 0;
                    let adjacentFireCount = 0;
                    for (let k = -1; k <= 1; k += 1) {
                        for (let l = -1; l <= 1; l += 1) {
                            const x = (i + k + numCols) % numCols; // Wrap around horizontally
                            const y = (j + l + numRows) % numRows; // Wrap around vertically

                            if (grid[x][y] == 1 || grid[x][y] == 3) { // There is a tree in the adjacent cell
                                sum += 1;
                            }
                            if (grid[x][y] == 2) { // There is fire in the adjacent cell
                                adjacentFireCount += 1;
                            }
                        }
                    }

                    if (grid[i][j] == 0 && adjacentFireCount == 0) {
                        if (Math.random() < sum * gridConfig.treeGrowChance) {
                            newGrid[i][j] = 1;
                        }
                    } else if (grid[i][j] == 1 || grid[i][j] == 3) {
                        if (adjacentFireCount == 0) {
                            if (Math.random() < gridConfig.fireSpawnChance) {
                                newGrid[i][j] = 2;
                            }
                        } else if (Math.random() < gridConfig.fireSpreadChance) {
                            newGrid[i][j] = 2;
                        }
                    } else if (grid[i][j] == 2) {
                        newGrid[i][j] = 0;
                    }

                    if (distanceToMouse < 5 && mousePosition.x != 0 && mousePosition.y != 0) {
                        if (newGrid[i][j] == 1 || newGrid[i][j] == 2) {
                            newGrid[i][j] = 3;
                        } else if (newGrid[i][j] == 0) {
                            // give it a better chance to grow
                            if (Math.random() < 0.5) {
                                newGrid[i][j] = 3;
                            }
                        }
                    }
                }
            }

            // Update the grid with the new values
            grid = newGrid;
        }

        // Update function for animation
        function update(timestamp) {
            const deltaTime = timestamp - lastFrameTime;
            if (deltaTime < frameTime) {
                requestAnimationFrame(update);
                return;
            }
            // console.log(deltaTime);
            lastFrameTime = timestamp;
            updateGrid(numCols, numRows);
            drawGrid();

            // Request the next frame
            requestAnimationFrame(update);
        }

        // Initial grid rendering and animation start
        drawGrid();
        requestAnimationFrame(update);

        // Cleanup event listener
        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
            window.removeEventListener("resize", handleResize);
        };
    }, [firesOn]);


    return (
        <div className="App">
            <canvas ref={canvasRef} width={canvasWidth} height={canvasHeight}></canvas>
            <div className="fire-simulation">
                {/* Add the semi-transparent overlay as a child */}
                <div className="simulation-overlay"></div>

                <canvas id="canvas" width={canvasWidth} height={canvasHeight}></canvas>
                <div className={`center`}>
                    <div className="about-me">
                        <div className="me-image-container">
                            <img src="/me_photo.jpg" alt="Gian's Photo" />
                        </div>
                        <div className="about-me-content">
                            <div className="info-container">
                                <div className="info-box">
                                    <p className="text">Heyo, I'm Gian</p>
                                    <p className="text-smaller">
                                        Senior at UVM studying data science, a researcher at the UVM SEGS Lab, and an intern at Green Mountain Power.
                                    </p>
                                </div>
                            </div>
                            <div className="social-buttons">
                                <a
                                    href="https://github.com/aiqojo"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img
                                        src={"/github-mark-white.svg"}
                                        alt="GitHub"
                                        className="social-icon-github"
                                    />
                                </a>
                                <a
                                    href="https://www.linkedin.com/in/giancercena/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img
                                        src={"/linkedin.svg"}
                                        alt="LinkedIn"
                                        className="social-icon"
                                    />
                                </a>
                                <a href="/Cercena_resume.pdf" target="_blank" rel="noopener noreferrer">
                                    <img
                                        src={"/icons8-resume-100.png"}
                                        alt="Resume"
                                        className="social-icon"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default App;
